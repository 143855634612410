<template>
    <div class="comment">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Comment'
}
</script>